<template>
  <div class="container">
    <div class="header" :data-text="joinTImeStr + '天前加入'"></div>
    <div class="main" :style="'background-image: url(' + avatar + ');'">
      <div class="info">
        <span class="nickname">{{ nickname }}</span>
        <div class="other">
          <div class="left">
            <img class="gender" :src="gender" alt="" />
            <span class="age">{{ age }}</span>
          </div>
          <span class="more">{{ constellation }} {{ mbti }}</span>
        </div>
        <div class="tags">
          <div v-for="tag in tags" :key="tag" class="tag">{{ tag.name }} +{{ tag.count }}</div>
        </div>
      </div>
      <div class="mask"></div>
    </div>
    <div class="footer">
      <div class="sign">{{ sign }}</div>
      <span class="label">扫描二维码，和我一起玩吧~</span>
      <img class="qrcode" :src="qrcode" alt="" />
    </div>
  </div>
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  data() {
    return {
      joinTImeStr: "",
      userId: "",
      age: "",
      gender: "",
      mbti: "",
      nickname: "",
      tags: [],
      sign: "",
      avatar: "",
      constellation: "",
      qrcode: "",
    }
  },
  created() {
    const { joinTImeStr, userId, age, gender, mbti, nickname, tags, sign, avatar, constellation } = this.$route.query;
    console.log({ joinTImeStr, userId, age, gender, mbti, nickname, tags, sign, avatar, constellation });
    this.joinTImeStr = joinTImeStr;
    this.userId = userId;
    this.age = age;
    this.gender = gender == 1 
      ? 'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a88ba-8fd3-035d-5985-8ee40252cc3d?x-oss-process=style/origin' 
      : 'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a88ba-8fd1-49cd-c3a3-21feb66e0743?x-oss-process=style/origin';
    this.mbti = mbti;
    this.nickname = nickname;
    this.tags = JSON.parse(decodeURIComponent(tags.replace(/\\/g, "") || "[]"));
    this.sign = sign;
    this.avatar = avatar;
    this.constellation = constellation;
    this.encodeCumenId(userId);
  },
  methods: {
    /** 编码 ID */ 
    encodeCumenId(userId) {
      $fetch
        .post("EncodeCumenId", { id: userId })
        .then((res) => {
          this.getWechatCode(res.id);
        });
    },
    /** 生成微信二维码 */ 
    getWechatCode(scene) {
      const _ = this;
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/partner/pages/profile/index',
          scene,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
        });
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

$multipleCount: 1.119;

.container {
  position: relative;
  width: 100%;
  height: 157.6vw;
  background-image: linear-gradient(148deg, #ccf6ff, #fff);

  > .header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: px2rem(122 * $multipleCount);
    background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a8863-f2d6-54fb-f7cc-699d5b60442e?x-oss-process=style/origin") no-repeat center top / contain;
    z-index: 1;

    &::after {
      content: attr(data-text);
      position: absolute;
      left: 50%;
      bottom: px2rem(8 * $multipleCount);
      transform: translateX(-50%);
      width: px2rem(160 * $multipleCount);
      height: px2rem(24 * $multipleCount);
      line-height: px2rem(28 * $multipleCount);
      color: #222;
      font-size: px2rem(20 * $multipleCount);
      font-family: PingFangSC-Medium;
      text-align: center;
    }
  }

  > .main {
    @include row(flex-start, flex-end);
    position: absolute;
    top: px2rem(72);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - px2rem(64 * $multipleCount));
    height: px2rem(752 * $multipleCount);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;

    > .info {
      @include column;
      position: relative;
      width: 100%;
      padding: 0 px2rem(32 * $multipleCount);
      height: px2rem(208 * $multipleCount);
      z-index: 1;

      > .nickname {
        width: 100%;
        color: #fff;
        height: px2rem(48 * $multipleCount);
        font-size: px2rem(40 * $multipleCount);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        margin-top: px2rem(12 * $multipleCount);
      }

      > .other {
        @include row(flex-start, center);
        width: px2rem(256 * $multipleCount);
        height: px2rem(40 * $multipleCount);
        margin-top: px2rem(16 * $multipleCount);
        background-image: linear-gradient(110deg, #222 0 39%, #1ffd91 39% 100%);
        border-radius: px2rem(4 * $multipleCount);
        
        > .left {
          @include row(center, center);
          width: px2rem(106 * $multipleCount);
          height: 100%;

          > .gender {
            width: px2rem(24 * $multipleCount);
            height: px2rem(24 * $multipleCount);
          }

          > .age {
            color: #1ffd91;
            font-size: px2rem(24 * $multipleCount);
            margin-left: px2rem(8 * $multipleCount);
            font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
          }
        }


        > .more {
          color: #222;
          font-size: px2rem(24 * $multipleCount);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        }
      }

      > .tags {
        @include row;
        margin-top: px2rem(12 * $multipleCount);
          overflow: hidden;

        > .tag {
          flex-shrink:0;
          color: #fff;
          font-size: px2rem(24 * $multipleCount);
          height: px2rem(56 * $multipleCount);
          line-height: px2rem(56 * $multipleCount);
          font-family: PingFangSC-semiBold;
          padding: 0 px2rem(16 * $multipleCount);
          margin-right: px2rem(16 * $multipleCount);
          background-color: rgba(0, 0, 0, .2);
          backdrop-filter: blur(20px);
          border-radius: px2rem(32 * $multipleCount);
        }
      }
    }

    > .mask {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: px2rem(208 * $multipleCount);
      background: linear-gradient(180deg, transparent, #000 100%); 
      opacity: .8;
    }
  }

  .footer {
    @include column;
    position: absolute;
    top: px2rem(864 * $multipleCount);
    left: px2rem(24 * $multipleCount);
    width: calc(100% - px2rem(48 * $multipleCount));

    > .sign {
      @include multi-ellipsis(2);
      position: relative;
      width: px2rem(420 * $multipleCount);
      height: px2rem(96 * $multipleCount);
      line-height: px2rem(48 * $multipleCount);
      font-size: px2rem(28 * $multipleCount);
      text-indent: px2rem(50 * $multipleCount);
      font-family: PingFangSC-semiBold;

      &::before {
        content: "";
        position: absolute;
        left: px2rem(6 * $multipleCount);
        top: px2rem(6 * $multipleCount);
        width: px2rem(32 * $multipleCount);
        height: px2rem(32 * $multipleCount);
        background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a8863-f2d8-2d87-bd98-f8175c4266ef?x-oss-process=style/origin') no-repeat center / cover;
      }
    }

    > .label {
      color: #a3a3a3;
      font-size: px2rem(24 * $multipleCount);
      margin-top: px2rem(8 * $multipleCount);
    }

    > .qrcode {
      position: absolute;
      right: px2rem(8 * $multipleCount);
      bottom: 0;
      width: px2rem(144 * $multipleCount);
      height: px2rem(144 * $multipleCount);
      padding: px2rem(8 * $multipleCount);
      background-color: #fff;
      border-radius: px2rem(12 * $multipleCount);
    }
  }
}
</style>
